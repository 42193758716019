<template>
  <Portal
    :menus="portal"
    :resourceType="resourceType"
    :resourceServer="parameter.resourceServer"
    :resourceId="parameter.resourceId"
    :endpoint="parameter.endpoint"
    :firewall="firewall"
  ></Portal>
</template>

<script>
export default {
  props: {
    resourceType: String,
    parameter: Object,
  },
  components: {
    Portal: () => import("cms_infrastructure_web/src/sdk/Admin/Portal/Portal.vue"),
  },
  data: function () {
    return {
      portal: {
        User: [
          {
            name: "User",
            content: "components/User/Portal-User.vue",
          },
          {
            name: "User Role",
            content: "components/UserRole/Portal-UserRole.vue",
          },
        ],
        Solution: [
          {
            name: "Solution",
            content: "components/Solution/Portal-Solution.vue",
          },
          {
            name: "Solution Role",
            content: "components/SolutionRole/Portal-SolutionRole.vue",
          },
          {
            name: "Solution Consent",
            content: "components/SolutionConsent/Portal-SolutionConsent.vue",
          },
        ],
      },
      firewall: [
        { label: "All", value: "Default" },
        { label: "Backend", value: "Backend" },
        { label: "Frontend", value: "Frontend" },
        { label: "Security", value: "Security" },
        { label: "Debug", value: "Debug" },
        { label: "Analysis", value: "Analysis" },
      ],
    };
  },
};
</script>
